<template>
  <li ref="network" class="tabsDashboard__tab js-tab-item" data-type="network">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text"><b>New sign ups</b> / Flyers</div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="network"/>
          <span class="tabsDashboard__tab-icon-count">4</span>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="">To see full details</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__network">
        <div
          v-for="item of flyersList"
          :key="item.id"
          class="tabsDashboard__network-item"
        >
          <router-link class="tabsDashboard__network-name" to="/dashboard/profile">{{item.name}}</router-link>
          <div class="tabsDashboard__network-phone">{{item.phone}}</div>
          <div class="tabsDashboard__network-email">{{item.email}}</div>
          <div class="tabsDashboard__network-info">{{item.date.typeOne}}</div>
        </div>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="">See all</router-link>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['visible'],
    name: 'TabsDashboardNetwork',
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.network)
      },
    },
    computed: {
      flyersList () {
        return this.$store.state.network.list
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.network.getAttribute('data-type'))
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
