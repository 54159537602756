<template>
  <li ref="fleet" class="tabsDashboard__tab js-tab-item" data-type="fleet">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div v-if="type === 'provider'" class="tabsDashboard__tab-text">Fleet</div>
        <div v-else class="tabsDashboard__tab-text">Aircraft <b>uploads</b></div>
        <div class="tabsDashboard__tab-icon"><SvgIcon name="plane-provider"/></div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="">See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__fleet">
        <div v-if="!fleet.length" class="tabsDashboard__fleet-empty js-aircraft-empty">No aircraft <b>yet</b></div>
        <div class="tabsDashboard__fleet-list">
          <router-link
            v-for="item of fleet"
            :key="item.id"
            class="tabsDashboard__fleet-list-item js-aircraft-item"
            :to="'/admin/aircraft-uploads/' + item.id"
          >
            <div class="tabsDashboard__fleet-list-item-title"><b>Aircraft {{item.counter}}: </b>{{item.title}}</div>
            <div class="tabsDashboard__fleet-list-item-remove js-remove-aircraft" @click="showModal(item.id)">
              <SvgIcon name="close"/>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="">See full</router-link>
    </div>
    <QuestionPopup
      :name="'questionPopupFleet'"
      :title="'The aircraft will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'

  export default {
    props: ['visible', 'type'],
    name: 'TabsDashboardFleet',
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.fleet)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.fleet.getAttribute('data-type'))
      },
      showModal (id) {
        this.$modal.show('questionPopupFleet')
        this.$store.commit('insertCurrentFleet', id)
      },
      remove () {
        this.$store.commit('removeItemFleet')
      },
    },
    computed: {
      fleet () {
        return this.$store.state.fleet.list
      },
    },
    components: {
      SvgIcon,
      QuestionPopup,
    },
  }
</script>
