<template>
  <div class="tabsDashboard">
    <vue-scroll :ops="ops">
      <div class="tabsDashboard__header">
        <div class="tabsDashboard__title">Customize your dashboard</div>
        <div class="tabsDashboard__text">
          <span class="text text--desktop">You can put any desired tab on the dashboard. Just drag it from the left vertical menu</span>
          <span class="text text--mobile">You can put any desired tab on the dashboard. “ADD NEW TAB” at the bottom of the page</span>
        </div>
      </div>
      <ul class="tabsDashboard__content js-tab-list">
        <div style="display:none;">
        <TabsDashboardFlights :type="'admin'" :visible="sections.flights" @hideSection="hideSection"/>
        <TabsDashboardProfile :type="'admin'" :visible="sections.profile" @hideSection="hideSection"/>
        <TabsDashboardMessages :visible="sections.messages" @hideSection="hideSection"/>
        <TabsDashboardFlyRewards :type="'admin'" :visible="sections.flyRewards" @hideSection="hideSection"/>
        <TabsDashboardRequests :type="'admin'" :visible="sections.requests" @hideSection="hideSection"/>
        <TabsDashboardNetwork :visible="sections.network" @hideSection="hideSection"/>
        <TabsDashboardProviderRequests :visible="sections.signUp" @hideSection="hideSection"/>
        <TabsDashboardFleet :type="'admin'" :visible="sections.fleet" @hideSection="hideSection"/>
        <TabsDashboardCharterUploads :type="'admin'" :visible="sections.charter" @hideSection="hideSection"/>
        <TabsDashboardFlightUploads :type="'admin'" :visible="sections.flight" @hideSection="hideSection"/>
        <TabsDashboardAuctions :type="'admin'" :visible="sections.auctions" @hideSection="hideSection"/>
        </div>
        <TabsDashboardAdd :type="'admin'" @showSection="showSection"/>
      </ul>
    </vue-scroll>
  </div>

</template>

<script>
import TabsDashboardFlights from '@/components/dashboard/tabs/TabsDashboardFlights';
import TabsDashboardProfile from '@/components/dashboard/tabs/TabsDashboardProfile';
import TabsDashboardMessages from '@/components/dashboard/tabs/TabsDashboardMessages';
import TabsDashboardFlyRewards from '@/components/dashboard/tabs/TabsDashboardFlyRewards';
import TabsDashboardRequests from '@/components/dashboard/tabs/TabsDashboardRequests';
import TabsDashboardNetwork from '@/components/dashboard/tabs/TabsDashboardNetwork';
import TabsDashboardProviderRequests from '@/components/dashboard/tabs/TabsDashboardProviderRequests';
import TabsDashboardFleet from '@/components/dashboard/tabs/TabsDashboardFleet';
import TabsDashboardCharterUploads from '@/components/dashboard/tabs/TabsDashboardCharterUploads';
import TabsDashboardFlightUploads from '@/components/dashboard/tabs/TabsDashboardFlightUploads';
import TabsDashboardAuctions from '@/components/dashboard/tabs/TabsDashboardAuctions';
import TabsDashboardAdd from '@/components/dashboard/tabs/TabsDashboardAdd';
import '@/views/dashboard/TabsDashboard.styl';

export default {
  name: 'DashboardAdmin',
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    sections: {
      flights: true,
      profile: true,
      messages: true,
      flyRewards: true,
      requests: true,
      network: true,
      signUp: true,
      fleet: true,
      charter: true,
      flight: true,
      auctions: true,
      requestEmpty: true,
    },
  }),
  methods: {
    hideSection (value) {
      this.sections[`${value}`] = false;
    },
    showSection (value) {
      this.sections[`${value}`] = true;
    },
  },
  components: {
    TabsDashboardFlights,
    TabsDashboardProfile,
    TabsDashboardMessages,
    TabsDashboardFlyRewards,
    TabsDashboardRequests,
    TabsDashboardNetwork,
    TabsDashboardProviderRequests,
    TabsDashboardFleet,
    TabsDashboardCharterUploads,
    TabsDashboardFlightUploads,
    TabsDashboardAuctions,
    TabsDashboardAdd,
  },
};
</script>
