<template>
  <li class="tabsDashboard__tab tabsDashboard__tab--add">
    <div class="tabsDashboard__add js-acc-item js-accordion-item">
      <div class="tabsDashboard__add-header js-acc-title">
      <!-- <div class="tabsDashboard__add-header js-acc-title" @click="showToggle"> -->
        <div class="tabsDashboard__add-title">add new tab</div>
        <div class="tabsDashboard__add-icon">
          <svg width="17" height="17" viewBox="0 0 17 17">
            <path
              d="M8.5 0C3.81323 0 0 3.8129 0 8.5C0 13.1871 3.81323 17 8.5 17C13.1868 17 17 13.1871 17 8.5C17 3.8129 13.1868 0 8.5 0ZM8.5 16.3462C4.17383 16.3462 0.653846 12.8262 0.653846 8.5C0.653846 4.17383 4.17383 0.653846 8.5 0.653846C12.8262 0.653846 16.3462 4.17383 16.3462 8.5C16.3462 12.8262 12.8262 16.3462 8.5 16.3462Z"></path>
            <circle cx="8.5" cy="8.5" r="8.5"></circle>
            <path
              d="M12.5859 8.17308H8.82632V4.57692C8.82632 4.39613 8.67986 4.25 8.4994 4.25C8.31894 4.25 8.17248 4.39613 8.17248 4.57692V8.17308H4.41286C4.2324 8.17308 4.08594 8.31921 4.08594 8.5C4.08594 8.68079 4.2324 8.82692 4.41286 8.82692H8.17248V12.75C8.17248 12.9308 8.31894 13.0769 8.4994 13.0769C8.67986 13.0769 8.82632 12.9308 8.82632 12.75V8.82692H12.5859C12.7664 8.82692 12.9129 8.68079 12.9129 8.5C12.9129 8.31921 12.7664 8.17308 12.5859 8.17308Z"></path>
          </svg>
        </div>
      </div>
      <div class="js-acc-content">
        <div class="tabsDashboard__add-content">
          <div class="tabsDashboard__add-list">
            <div style="display:none;"> <SvgIcon /> </div>
            <!-- <div
              v-for="(item, index) of tabs.nav"
              :key="index"
              class="tabsDashboard__add-list-item js-tab-add"
              @click="$emit('showSection', item.type)"
            >
              <span class="tabsDashboard__add-list-icon"><SvgIcon :name="item.icon"/></span>
              <span class="tabsDashboard__add-list-text" v-html="item.text"></span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['type'],
    name: 'TabsDashboardAdd',
    data: () => ({
      flyer: {
        nav: [{
          text: 'Profile',
          icon: 'user2',
          type: 'profile',
        }, {
          text: 'Settings & security',
          icon: 'locked',
          type: 'settings',
        }, {
          text: 'Passenger identification',
          icon: 'card',
          type: 'passId',
        }, {
          text: 'FLY<b>Rewards</b>',
          icon: 'giftbox',
          type: 'flyRewards',
        }, {
          text: 'Route alerts',
          icon: 'alarm',
          type: 'alerts',
        }, {
          text: 'Auctions (BIDs)',
          icon: 'auction',
          type: 'auctions',
        }, {
          text: 'Fly Empty',
          icon: 'discuss',
          type: 'requests',
        }, {
          text: 'Sell seats',
          icon: 'ticket',
          type: 'seats',
        }, {
          text: 'Flights',
          icon: 'distance',
          type: 'flights',
        }, {
          text: 'Messages',
          icon: 'message',
          type: 'messages',
        }, {
          text: 'Payment information',
          icon: 'wallet',
          type: 'payment',
        }],
      },
      provider: {
        nav: [{
          text: 'Profile',
          icon: 'user2',
          type: 'profile',
        }, {
          text: 'Personnel',
          icon: 'pilot-hat',
          type: 'personnel',
        }, {
          text: 'Fleet',
          icon: 'plane-provider',
          type: 'fleet',
        }, {
          text: 'Charter availability uploads',
          icon: 'calendar-aircraft',
          type: 'charter',
        }, {
          text: 'Flight availability uploads',
          icon: 'calendar-checked',
          type: 'flight',
        }, {
          text: 'Booking confirmation',
          icon: 'confirm',
          type: 'booking',
        }, {
          text: 'Flights',
          icon: 'distance',
          type: 'flights',
        }, {
          text: 'FLY<b>Rewards</b>',
          icon: 'giftbox',
          type: 'flyRewards',
        }, {
          text: 'Payment and bank info',
          icon: 'wallet',
          type: 'payment',
        }, {
          text: 'Messages',
          icon: 'message',
          type: 'messages',
        }, {
          text: 'Requests',
          icon: 'discuss',
          type: 'requests',
        }, {
          text: 'Auctions bids and offers',
          icon: 'auction',
          type: 'auctions',
        }],
      },
      admin: {
        nav: [{
          text: 'Profile',
          icon: 'user2',
          type: 'profile',
        }, {
          text: 'Network activity',
          icon: 'network',
          type: 'network',
        }, {
          text: 'Aircraft provider requests',
          icon: 'discuss',
          type: 'signUp',
        }, {
          text: 'Flight requests',
          icon: 'calendar-aircraft',
          type: 'requests',
        }, {
          text: 'Flights',
          icon: 'distance',
          type: 'flights',
        }, {
          text: 'Promo code generator',
          icon: 'plane-provider',
          type: 'promo-codes-generator',
        }, {
          text: 'Users subscriptions',
          icon: 'plane-provider',
          type: 'users-subscriptions',
        }, {
          text: 'Gifts generator',
          icon: 'plane-provider',
          type: 'gifts-generator',
        }, {
          text: 'Auctions',
          icon: 'auction',
          type: 'auctions',
        }, {
          text: 'Aircraft uploads',
          icon: 'plane-provider',
          type: 'fleet',
        }, {
          text: 'Charter uploads',
          icon: 'world',
          type: 'charter',
        }, {
          text: 'Flight uploads',
          icon: 'aeroplane2',
          type: 'flight',
        }, {
          text: 'FLY<b>Rewards</b>',
          icon: 'giftbox',
          type: 'flyRewards',
        }, {
          text: 'Messages',
          icon: 'message',
          type: 'messages',
        }, {
          text: 'Argus statistic',
          icon: 'plane-provider',
          type: 'argus-statistic',
        }, {
          text: 'TFR sign Ups',
          icon: 'plane-provider',
          type: 'tfr-sign-ups',
        }, {
          text: 'Get all feed',
          icon: 'plane-provider',
          type: 'get-all-feed',
        }, {
          text: 'Newsletter generator',
          icon: 'plane-provider',
          type: 'newsletter-generator',
        }, {
          text: 'Remove unsubscribed',
          icon: 'plane-provider',
          type: 'remove-unsubscribed',
        }, {
          text: 'Subscription Analytics',
          icon: 'plane-provider',
          type: 'subscription-analytics',
        }],
      },
      list: [{
        name: 'Jane Doe',
      }, {
        name: 'Oxana Ivanova',
      }, {
        name: 'Jane Doe',
      }, {
        name: 'Oxana Ivanova',
      }, {
        name: 'Jane Doe',
      }],

    }),
    computed: {
      tabs () {
        switch (this.type) {
          case 'flyer':
            return this.flyer
          case 'provider':
            return this.provider
          case 'admin':
            return this.admin
          default:
            return false
        }
      },
    },
    methods: {
      showToggle (event) {
        const item = event.currentTarget.closest('.js-acc-item').querySelector('.js-acc-content')
        this.$root.animations.slideToggle(item)
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
