<template>
  <li ref="flight" class="tabsDashboard__tab js-tab-item" data-type="flight">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div v-if="type === 'provider'" class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text">Flight <b>availability upload</b></div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="calendar-checked"/>
        </div>
      </div>
      <div v-else class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text">Flight <b>uploads</b></div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="aeroplane2"/>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="#">See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__charterUploads">
        <form
          class="form form--availability js-availabilityForm"
          :class="{'loading' : loading}"
          @submit.prevent="submitHandler"
        >
          <div class="form__labelGroup form__labelGroup--text">
            <label class="form__label form__label--select">
              <div class="form__caption">Aircraft number</div>
              <div class="form__field-wrapper">
                <div class="selectContainer js-select-flight-uploads"></div>
                <a-select
                  class="form__select"
                  :getPopupContainer="selectContainer"
                  @change="selectChange"
                  :default-value="fleet[0].value"
                  :disabled="loading"
                >
                  <a-select-option
                    v-for="(item, index) of fleet"
                    :key="index"
                    :value="item.value"
                  >
                    {{item.value}}
                  </a-select-option>
                </a-select>
              </div>
            </label>
            <div class="form__radioGroup">
              <label class="form__label form__label--radio">
                <input
                  type="radio"
                  class="form__radio js-changeWay"
                  v-model="route"
                  data-type="round"
                  data-text="From-To:"
                  value="round trip"
                  :disabled="loading"
                />
                <span class="form__radio-box">Round trip (two legs)</span>
              </label>
              <label class="form__label form__label--radio">
                <input
                  type="radio"
                  class="form__radio js-changeWay"
                  v-model="route"
                  data-type="one"
                  data-text="From:"
                  value="single legs"
                  :disabled="loading"
                />
                <span class="form__radio-box">Single legs</span>
              </label>
            </div>
            <div class="form__label form__label--button">
              <Spinner/>
              <button class="button form__button">
                <span class="text">Continue</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See full</router-link>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import '@/components/common/SelectCommon/SelectCommon.styl'

  export default {
    props: ['type', 'visible'],
    name: 'TabsDashboardFlightUploads',
    data: () => ({
      number: '',
      route: 'round trip',
      loading: false,
    }),
    computed: {
      fleet () {
        return this.$store.state.fleet.list
      },
    },
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.flight)
      },
    },
    created () {
      this.number = this.$store.state.fleet.list[0].value
    },
    mounted () {
      this.$root.fields.changeRadio('.form__radio')
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.flight.getAttribute('data-type'))
      },
      selectContainer () {
        return document.querySelector('.js-select-flight-uploads')
      },
      selectChange (value) {
        this.number = value
      },
      submitHandler () {
        const data = {
          number: this.number,
          route: this.route,
        }

        this.loading = true
        console.log(data)
      },
    },
    components: {
      SvgIcon,
      Spinner,
    },
  }
</script>
