<template>
  <li ref="charter" class="tabsDashboard__tab js-tab-item" data-type="charter">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div v-if="type === 'provider'" class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text">Charter <b>availability upload</b></div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="calendar-aircraft"/>
        </div>
      </div>
      <div v-else class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text">Charter <b>uploads</b></div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="world"/>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="#">See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__charterUploads">
        <form
          class="form form--availability js-availabilityForm"
          :class="{'loading' : loading}"
          @submit.prevent="submitHandler"
        >
          <div class="form__labelGroup form__labelGroup--text">
            <label class="form__label form__label--select">
              <div class="form__caption">Aircraft number</div>
              <div class="form__field-wrapper">
                <div class="selectContainer js-select-charter-uploads"></div>
                <a-select
                  class="form__select"
                  :getPopupContainer="selectContainer"
                  @change="selectChange"
                  :default-value="fleet[0].value"
                  :disabled="loading"
                >
                  <a-select-option
                    v-for="(item, index) of fleet"
                    :key="index"
                    :value="item.value"
                  >
                    {{item.value}}
                  </a-select-option>
                </a-select>
              </div>
            </label>
            <label
              class="form__label form__label--text">
              <div class="form__caption">Aircraft base:</div>
              <div class="form__field-wrapper">
                <input
                  class="form__field"
                  type="text"
                  v-model="base"
                  placeholder="Enter here"
                  autocomplete="off"
                  :disabled="loading"
                />
                <span
                  v-if="$v.base.$dirty && !$v.base.required"
                  class="form__field-invalid form__field-invalid--right-side"
                >
                  This field is required
                </span>
              </div>
            </label>
            <div class="form__label form__label--button">
              <Spinner/>
              <button class="button form__button">
                <span class="text">Continue</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See full</router-link>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import { required } from 'vuelidate/lib/validators'
  import '@/components/common/SelectCommon/SelectCommon.styl'

  export default {
    props: ['type', 'visible'],
    name: 'TabsDashboardCharterUploads',
    data: () => ({
      number: '',
      base: '',
      loading: false,
    }),
    validations: {
      base: { required },
    },
    computed: {
      fleet () {
        return this.$store.state.fleet.list
      },
    },
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.charter)
      },
    },
    created () {
      this.number = this.$store.state.fleet.list[0].value
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.charter.getAttribute('data-type'))
      },
      selectContainer () {
        return document.querySelector('.js-select-charter-uploads')
      },
      selectChange (value) {
        this.number = value
      },
      submitHandler () {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return false
        }

        const data = {
          number: this.number,
          base: this.base,
        }

        this.loading = true
        console.log(data)
      },
    },
    components: {
      SvgIcon,
      Spinner,
    },
  }
</script>
