<template>
  <li ref="signUp" class="tabsDashboard__tab js-tab-item" data-type="signUp">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text"><b>Aircraft provider</b> requests</div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="discuss"/>
          <span class="tabsDashboard__tab-icon-count js-sign-up-request-count">4</span>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="">To see full details</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__signUp">
        <div v-if="!requestsList.length" class="tabsDashboard__empty js-sign-up-request-empty">no requests</div>
        <div
          v-for="item of requestsList"
          :key="item.id"
          class="tabsDashboard__signUp-item js-sign-up-request-item"
          :class="{confirmation : item.confirmation}"
        >
          <router-link
            class="tabsDashboard__signUp-name"
            to=""
          >
            {{item.name}}
          </router-link>
          <div class="tabsDashboard__signUp-phone">{{item.phone}}</div>
          <div class="tabsDashboard__signUp-email">{{item.email}}</div>
          <div class="tabsDashboard__signUp-button-wr">
            <div
              class="tabsDashboard__signUp-button tabsDashboard__signUp-button--confirm js-sign-up-request-confirm"
              @click="addConfirmation(item.id)"
            >
              <SvgIcon name="checked"/>
            </div>
            <span
              class="tabsDashboard__signUp-button tabsDashboard__signUp-button--remove js-sign-up-request-remove"
              @click="showModal(item.id)"
            ><SvgIcon name="close"/>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link
        class="tabsDashboard__tab-link forMobile"
        to=""
      >
        See all
      </router-link>
    </div>
    <QuestionPopup
      :name="'questionPopupProviderRequests'"
      :title="'The request will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'

  export default {
    props: ['visible'],
    name: 'TabsDashboardProviderRequests',
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.signUp)
      },
    },
    computed: {
      requestsList () {
        return this.$store.state.adminRequests.providerRequests
      },
    },
    methods: {
      addConfirmation (id) {
        this.$store.commit('addConfirmationRequest', id)
      },
      showModal (id) {
        this.$modal.show('questionPopupProviderRequests')
        this.$store.commit('insertCurrentRequest', id)
      },
      remove () {
        this.$store.commit('removeItemRequest')
      },
      closeSection () {
        this.$emit('hideSection', this.$refs.signUp.getAttribute('data-type'))
      },
    },
    components: {
      SvgIcon,
      QuestionPopup,
    },
  }
</script>
